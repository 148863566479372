<template>
  <div>
    <Highlight></Highlight>
  </div>
</template>

<script>
import Highlight from "@/components/Highlight"
export default {
  components: {
    Highlight
  }
}
</script>

<style></style>